/*
 * @Author: Suroc
 * @Date: 2024-06-24 11:55:44
 * @LastEditTime: 2024-11-17 22:38:35
 * @Description: 公共函数
 */
import { showToast } from 'vant';

let Tool = {
  /**
   * @description 全局消息提示
   * @param {msg} 提示内容
   */
  Toasts: (msg) => {
    showToast(msg);
  }
};


export default Tool